import api from '@/base/utils/request';

// 列表数据
export const indexList = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips/index',
    method: 'post',
    data
  });
};
// 软删除
export const softDelete = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips/softDelete',
    method: 'post',
    data
  });
};
//硬删除
export const Delete = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips/del',
    method: 'post',
    data
  });
};
//还原
export const getPutBack = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips/putBack',
    method: 'post',
    data
  });
};
//修改运营数据
export const getUpdateVirtual = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips/updateVirtual',
    method: 'post',
    data
  });
};
//详情
export const getUpdaDetail = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips/detail',
    method: 'post',
    data
  });
};
//评论列表
export const getUpdaContentAll = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips_comment/ContentAll',
    method: 'post',
    data
  });
};
//心得保存
export const getSave = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips/save',
    method: 'post',
    data
  });
};

// 审核通过
export const check = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips/check',
    method: 'post',
    data
  });
};

// 审核不通过
export const uncheck = data => {
  return api({
    url: '/admin/cyc_activity/activity_tips/uncheck',
    method: 'post',
    data
  });
};