<template>
  <LayoutFilter :onFilter="ok" :toggleCollapseEnable="false" :onReset="resetForm">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="45px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
      <div class="filter-item">
        <el-form-item label="状态：" prop="check_status">
          <el-select v-model="form.check_status" placeholder="审核状态">
            <el-option label="全部" :key="-1" :value="-1"></el-option>
            <el-option label="待审核" :key="0" :value="0"></el-option>
            <el-option label="已通过" :key="2" :value="2"></el-option>
            <el-option label="未通过" :key="1" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入名称"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { parentToList, PostOptions } from "../../api/experience-list";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },

    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
    };
  },

  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>