<template>
  <div class="list-page">
    <SourceScreen :filterForm="filterForm" :uploadFilter="ok"></SourceScreen>
    <list-tabs
      v-model="filterForm.tab"
      :isLoading="loading"
      :tabs="tabArr"
      @tab-click="handleChangeTab"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <template
          v-if="filterForm.tab == 'report' || filterForm.tab == 'normal'"
        >
          <el-button type="success" size="small" @click="handlePass(-1, 1)"
            >通过</el-button
          >
          <el-button type="warning" size="small" @click="openUncheck()"
            >不通过</el-button
          >
          <el-button type="primary" size="small" @click="blockComment(-1)"
            >拉黑</el-button
          >
        </template>
        <el-button
          size="small"
          v-if="filterForm.tab == 'blacklist'"
          @click="unDelBlack(-1)"
          >移出黑名单</el-button
        >
        <el-button
          size="small"
          v-if="filterForm.tab == 'deleted'"
          type="success"
          @click="handleReduct(-1)"
          >还原
        </el-button>
        <el-button
          size="small"
          v-if="filterForm.tab != 'blacklist'"
          type="danger"
          @click="handleDel(-1)"
          >删除
        </el-button>
      </div>

      <el-table
        :data="list"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        class="thead-light"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="所属内容" prop="" width="200">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title=""
              width="300"
              trigger="hover"
              :content="scope.row.target_text ? scope.row.target_text : '-'"
            >
              <span slot="reference" class="block text-ellipsis">
                {{ scope.row.target_text ? scope.row.target_text : "-" }}
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="评论者ID" prop="user_id"></el-table-column>
        <el-table-column label="评论内容" prop="" width="200">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title=""
              width="300"
              trigger="hover"
              :content="scope.row.content"
            >
              <span
                slot="reference"
                class="block text-ellipsis"
                style="width: 90%"
                >{{ scope.row.content }}</span
              >
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="评论者" show-overflow-tooltip min-width="140">
          <template slot-scope="scope">{{ scope.row.nickname }}</template>
        </el-table-column>
        <el-table-column
          label="回复"
          prop="replies_count"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          label="用户点赞"
          sortable="custom"
          prop="likes_count"
          width="100"
        ></el-table-column>
        <el-table-column label="运营点赞" min-width="100">
          <template slot-scope="scope">
            <el-input
              style="width: 100px"
              v-model="scope.row.praise_count"
              type="text"
              @keyup.enter.native="$event.target.blur"
              @blur="updateIsShow($event, scope.row.id, scope.$index)"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          v-if="filterForm.tab === 'report'"
          label="举报"
          sortable="custom"
          prop="report"
          width="80"
        >
        </el-table-column>
        <el-table-column
          label="评论时间"
          prop="create_time"
          sortable="custom"
          width="160"
        ></el-table-column>
        <el-table-column label="审核状态">
          <template slot-scope="scope">
            <span>
              <span
                :class="[
                  'status-dot',
                  scope.row.check_status === 0
                    ? 'yellow'
                    : scope.row.check_status === 1
                    ? 'gray'
                    : 'green',
                ]"
              ></span>
              {{ scope.row.check_text }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          :width="filterForm.tab === 'deleted' ? 180 : 120"
        >
          <template slot-scope="scope">
            <el-button @click="viewDetail(scope.row.id)" type="text"
              >查看</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <template v-if="filterForm.tab === 'deleted'">
              <el-button @click="handleReduct(scope.row.id)" type="text"
                >还原</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button @click="handleDel(scope.row.id, 1)" type="text"
                >删除</el-button
              >
            </template>
            <el-button
              @click="unDelBlack(scope.row.id)"
              type="text"
              v-if="filterForm.tab === 'report'"
              >移出
            </el-button>
            <el-dropdown
              style="height: 40px; line-height: 40px"
              v-if="filterForm.tab !== 'deleted' && filterForm.tab !== 'report'"
              @command="handleDropdownSelect($event, scope.$index)"
            >
              <span style="color: #3576ff" class="el-dropdown-link">更多</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="check"
                  v-if="
                    scope.row.check_status === 1 || scope.row.check_status === 0
                  "
                  >通过</el-dropdown-item
                >
                <el-dropdown-item
                  command="nocheck"
                  v-if="
                    scope.row.check_status === 2 || scope.row.check_status === 0
                  "
                  >不通过</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="scope.row.check_status === 2"
                  command="reply"
                  >回复</el-dropdown-item
                >
                <el-dropdown-item command="block">拉黑</el-dropdown-item>
                <el-dropdown-item command="delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible="isReason"
      title="拉黑原因"
      width="504px"
      @close="isReason = false"
      class="dialog-vertical"
    >
      <el-input
        style="height: 150px"
        type="textarea"
        v-model="reason"
        placeholder="请输入原因"
      ></el-input>
      <template #footer>
        <el-button @click="isReason = false" size="medium">取消</el-button>
        <el-button
          @click="blockComment(operate_id)"
          size="medium"
          type="primary"
          >确定</el-button
        >
      </template>
    </el-dialog>

    <el-dialog
      :visible="isReply"
      title="回复评论"
      width="504px"
      @close="isReply = false"
      class="dialog-vertical"
    >
      <el-input
        style="height: 150px"
        type="textarea"
        v-model="reply_content"
        placeholder="请输入回复内容"
      >
      </el-input>
      <template #footer>
        <el-button @click="isReply = false" size="medium">取消</el-button>
        <el-button @click="sendReply" size="medium" type="primary"
          >确定</el-button
        >
      </template>
    </el-dialog>
    <check-dialog ref="checkDialog" @confirm="handleUncheck" />
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    ></Pagination>
  </div>
</template>

<script>
import {
  indexList,
  getReply,
  getIsCheck,
  getIsBlack,
  getDelBlack,
  Delete,
  softDelete,
  getPutBack,
  getUpdateVirtual,
} from "../../api/experience-comments";
import SourceScreen from "../../components/ExperienceComments/SourceScreen";
import Pagination from "@/base/components/Default/Pagination";
import CheckDialog from "@/modules/activity/components/ActivityList/CheckDialog";
export default {
  components: {
    CheckDialog,
    SourceScreen,
    Pagination,
  },
  data() {
    return {
      loading: true,
      isReason: false,
      isReply: false,
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //用户名
        // order_by: '', //排序
        is_desc: "", // 排序
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
        check_status: -1,
        id: 0,
      },
      filters: {
        normal: null,
        blacklist: null,
        report: null,
        deleted: null,
      },
      list: [],
      pageData: {},
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "黑名单", name: "blacklist" },
        { label: "被举报", name: "report" },
        { label: "回收站", name: "deleted" },
      ],
      selectArr: [],
      current_index: -1,
      virtual_like: "",
      operate_id: "",
      reply_content: "",
      reason: "",
    };
  },
  watch: {
    isReason(val) {
      if (!val) {
        this.reason = "";
      }
    },
  },
  created() {
    this.filterForm.id = this.$route.params.id;
    this.getIndexList(this.filterForm);
  },
  methods: {
    openUncheck(id = 0) {
      if (!this.selectArr.length && !id) {
        return this.$message.info("请选择数据项");
      }
      this.$refs["checkDialog"].open({ id });
    },
    handleUncheck(e) {
      console.log(e.id, e.content);
      this.handlePass(e.id || -1, 2, e.content);
    },
    viewDetail(e) {
      this.$router.push({
        name: "CommentsDetail",
        params: { id: e },
      });
    },
    changPage(data) {
      this.filterForm.page_size = data.page_size;
      const pageData = { ...this.filterForm, page: data.page };
      this.getIndexList(pageData);
    },
    // 列表“更多”选项
    handleDropdownSelect(e, i) {
      let single = this.list[i];
      switch (e) {
        case "reply":
          this.operate_id = single.id;
          this.reply_content = "";
          this.isReply = true;
          break;
        case "nocheck":
          // this.handlePass(single.id, 1);
          this.openUncheck(single.id);
          break;
        case "check":
          this.handlePass(single.id, 2);
          break;
        case "delete":
          this.handleDel(single.id);
          break;
        case "block":
          this.operate_id = single.id;
          this.blockComment(single.id);
      }
    },
    sendReply() {
      getReply({ id: this.operate_id, content: this.reply_content }).then(
        (res) => {
          this.$message.success(res.msg);
          this.isReply = false;
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        }
      );
    },
    handlePass(e, i, txt = "") {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        let title = i == 1 ? "是否通过选中评论？" : "是否不通过选中评论";
        this.$msgbox
          .confirm(
            i == 1 ? "是否通过选中评论？" : "是否不通过选中评论",
            "提示",
            {
              type: "info",
            }
          )
          .then((res) => {
            let k = {
              id: this.selectArr,
              check_status: i,
              reason: txt,
            };
            this.pass(k);
          })
          .catch(() => {});
      }
      if (e != -1) {
        this.$msgbox
          .confirm(i == 2 ? "是否通过该评论？" : "是否不通过该评论？", "提示", {
            type: "info",
          })
          .then((res) => {
            let k = {
              id: e,
              check_status: i,
              reason: txt,
            };
            this.pass(k);
          })
          .catch(() => {});
      }
    },
    pass(k) {
      this.loading = true;
      getIsCheck(k)
        .then((res) => {
          this.$message.success(res.msg);
          this.$refs["checkDialog"].close();
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 删除
    handleDel(e) {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        if (this.filterForm.tab == "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      }
      if (e != -1) {
        if (this.filterForm.tab == "normal") {
          this.$msgbox
            .confirm("确定要将此数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: e });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将此数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: e });
            })
            .catch((err) => {});
        }
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab === "normal") {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.getIndexList({
              ...this.filterForm,
              page: this.pageData.current_page,
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.getIndexList({
              ...this.filterForm,
              page: this.pageData.current_page,
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct(e) {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      }
      if (e != -1) {
        this.$msgbox
          .confirm("确定要将此数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: e });
          })
          .catch((err) => {});
      }
    },
    //移除
    unDelBlack(e) {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        this.$msgbox
          .confirm("确定要将选中数据移除黑名单吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.undataDelBlack({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据移除黑名单吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.undataDelBlack({ id: e });
          })
          .catch((err) => {});
      }
    },
    undataDelBlack(id) {
      this.loading = true;
      getDelBlack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //拉黑
    blockComment(e) {
      console.log(e);
      if (e === -1 && !this.selectArr.length) {
        return this.$message.info("请选择数据项");
      }
      if (!this.reason) {
        this.reason = "";
        this.isReason = true;
        return;
      }
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        // this.$msgbox
        //     .confirm("确定要将选中数据拉入黑名单吗？", "提示", {
        //         type: "info",
        //     })
        //     .then((res) => {
        //
        //     })
        //     .catch((err) => {});
        var i = {
          id: this.selectArr,
          reason: this.reason,
        };
        this.updataIsBlack(i);
      } else {
        // this.$msgbox
        //     .confirm("确定要将此数据拉入黑名单吗？", "提示", {
        //         type: "info",
        //     })
        //     .then((res) => {
        //
        //     })
        //     .catch((err) => {});
        var i = {
          id: e,
          reason: this.reason,
        };
        this.updataIsBlack(i);
      }
    },
    updataIsBlack(i) {
      this.loading = true;
      getIsBlack(i)
        .then((res) => {
          this.isReason = false;
          this.$message.success(res.msg);
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 还原数据
    Reduct(id) {
      this.loading = true;
      getPutBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    updateIsShow(e, id, i) {
      this.loading = true;
      getUpdateVirtual({ id: id, praise_count: Number(e.target.value) })
        .then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showInput(i) {
      this.current_index = i;
      this.virtual_like = this.list[i].praise_count;
    },
    hideInput() {
      this.current_index = -1;
    },
    // 勾选操作
    handleSelectionChange(selection) {
      console.log(selection);
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },

    sortChange() {},
    getIndexList(requestData) {
      this.list = [];
      this.pageData = {};
      this.loading = true;
      indexList(requestData)
        .then((res) => {
          const { data } = res;
          this.list = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 筛选回调
    ok(e) {
      // const pageData = {};
      this.filterForm = { ...e, page: 1 };
      this.getIndexList(this.filterForm);
    },
    //tab栏切换
    handleChangeTab(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          keyword: "", //用户名
          // order_by: '', //排序
          is_desc: "", // 排序
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页多少条数据
          check_status: -1,
          id: this.$route.params.id,
        };
      }
      this.getIndexList(this.filterForm);
    },
  },
};
</script>

<style lang="scss">
.text-ellipsis {
  @include clamp(5);
}
</style>
